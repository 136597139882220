import { createSlice } from "@reduxjs/toolkit";

const previousHrefSlice = createSlice({
    name: "previousHref",
    initialState: null,
    reducers: {
        setPreviousHref(state, action) {
            return action.payload;
        }
    }
});

export const {setPreviousHref} = previousHrefSlice.actions;

export const getPreviousHref = (state) => state.previousHref;

export default previousHrefSlice.reducer;