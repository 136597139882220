import { useGetArticleByIdQuery } from "../../store/apiSlice";
import { useParams, useLocation } from "react-router-dom";

import styles from './ArticlePage.module.css';

import ExpandLess from '@mui/icons-material/ExpandLess';

import { IconButton } from "@mui/material";

import Loader from "../Loader/Loader";
import Footer from "../Footer/Footer";
import ListOfContent from "../ListOfContent/ListOfContent";
import DropDownListOfContent from "../DropDownListOfContent/DropDownListOfContent";
import BackButton from "../BackButton/BackButton";

import { useEffect, useState } from "react";

export default function ArticlePage(){
    const params = useParams();
    const {
        data: article_data,
        isLoading,
        isError,
        isSuccess,
    } = useGetArticleByIdQuery(params.articleId);
    const { hash } = useLocation();
    const [offset, setOffset] = useState(0);
    const [showBackToTopButton, setShowBackToTopButton] = useState(false);

    const [mobileScreen, setMobileScreen] = useState(window.innerWidth <= 1024 ? true : false);

    useEffect(() => {
        window.scrollY < 500 && setShowBackToTopButton(false);
    }, [window.scrollY]);

    useEffect(() => {
        isSuccess && (document.title = `${article_data.title} - Aptaran`);
        const shielded_hash = shielding(decodeURI(hash));
        isSuccess && hash && document.querySelector(shielded_hash).scrollIntoView();
        isSuccess && !hash && window.scrollTo({top: 0, behavior: "instant"});
    }, [isSuccess, hash]);

    useEffect(() => {
        const onScroll = () => {
            (window.scrollY > 500 && window.scrollY < offset) ? setShowBackToTopButton(true) : setShowBackToTopButton(false);
            setOffset(window.scrollY);
        }
        window.addEventListener('wheel', onScroll, { passive: true });
        window.addEventListener('touchmove', onScroll, { passive: true });
        window.addEventListener('toucend', onScroll, { passive: true });
        return () => {
            window.removeEventListener('wheel', onScroll);
            window.removeEventListener('touchmove', onScroll);
            window.removeEventListener('touchend', onScroll);
        };
    }, [offset, showBackToTopButton]);

    useEffect(() => {
        const onResize = () => {
            window.innerWidth <= 1024 ? setMobileScreen(true) : setMobileScreen(false);
        }
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, []);

    let content;

    if(isLoading) {
        content = <div style={{marginTop:"calc(50vh - 6px)"}}><Loader /></div>;
    }
    else if(isError) {
        throw new Error("Ошибка загрузки");
    }
    else if(isSuccess){
        const markup = {__html: article_data.html};

        content = (
            <div className={styles.Main_container}>
                
                <article className={styles.Content_block}>
                    {mobileScreen && <BackButton />}
                    <DropDownListOfContent list_of_content={JSON.parse(article_data.listOfContents)} setShowBackToTopButton={setShowBackToTopButton} />
                    <div className={styles.Markup} dangerouslySetInnerHTML={markup}></div>
                </article>
                <ListOfContent list_of_content={JSON.parse(article_data.listOfContents)} setShowBackToTopButton={setShowBackToTopButton} />
            </div>
        );
    }

    return (
        <>
            <div className={styles.Wrapper}>
                {!mobileScreen && <BackButton />}
                {content}
                <div className={styles.RightPlaceholder}style={{marginLeft: "auto", width: 0}}></div>
                
            </div>
            
            <IconButton className={`${styles.BackToTopButton} ${showBackToTopButton && styles.BackToTopButtonShow}`}
             onClick={() => {
                window.scrollTo({top: 0});
                setShowBackToTopButton(false);
             }
            }
            > 
                <ExpandLess fontSize="large"/>
            </IconButton>
            
            <Footer />
        </>
    );
}


function shielding(str) {
    const regexp = new RegExp("[()]", "g");
    let match;
    let result = str;
    let i = 0;
    while ((match = regexp.exec(str)) != null) {
        result = result.slice(0, match.index + i) + "\\" + result.slice(match.index + i);
        i++;
    }
    return result;
} 