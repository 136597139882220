import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';

import ResponseTableItem from '../ResponseTableItem/ResponseTableItem';
import Loader from '../Loader/Loader';
import ErrorPage from '../ErrorPage/ErrorPage';

import { selectAllQueryElements } from '../../store/queryElementsInStrSlice';
import { useGetArticlesQuery, useGetCountByTagsQuery } from '../../store/apiSlice';

import styles from './ResponseTable.module.css';

const COUNT_OF_ARTICLES_FOR_ONE_PAGE = 16;

export default function ResponseTable(){
    const req_el_in_string = useSelector(selectAllQueryElements);
    const query_data = useOutletContext();

    const [page, setPage] = useState(0);
    const [isOverflow, setIsOverflow] = useState(false);
    const [allVisibleArticles, setAllVisibleArticles] = useState([]);

    let parametersForArticlesQuery = getParamForArticlesQuery(query_data, req_el_in_string);

    const {
        data: page_with_articles,
        isLoading,
        isError,
        isSuccess,
        error,
    } = useGetArticlesQuery({parametersForArticlesQuery, page});

    const {
        data: count,
        isSuccess: countIsSuccess,
        isLoading: countIsLoading
    } = useGetCountByTagsQuery(parametersForArticlesQuery);

    let content;
    
    if(isLoading || countIsLoading){
        content = <Loader />
    }
    else if(isSuccess && countIsSuccess){
        if(COUNT_OF_ARTICLES_FOR_ONE_PAGE * (page + 1) < count.value){
            if(!isOverflow){
                setIsOverflow(true);
            }
        }
        else{
            if(isOverflow){
                setIsOverflow(false);
            }
            
        }

        !allVisibleArticles.includes(page_with_articles[0]) && setAllVisibleArticles((state) => state.concat(page_with_articles));
        content = allVisibleArticles?.map(article => <ResponseTableItem key={article.id} id={article.id} title={article.title}/>);
    }
    else if(isError){
        const message = error.data.message == "Articles matching json_tags not found" ? 'По запросу ничего не найдено' : 'Что-то пошло не так :(';
        content = <ErrorPage message={message}/>
    }

    return (
        <div className={styles.Wrapper}>
            <div className={styles.ResponseTable}>
                {content} 
            </div>
            {isOverflow && <div className={styles.ExpandButton} onClick={() => setPage((state) => state + 1)}>
                <span className={styles.TextOfExpandButton}>
                    {isLoading || countIsLoading ? <Loader /> : "Показать ещё"}
                </span>
            </div>}
        </div>
        
    );
}

function getParamForArticlesQuery(query_data, req_el_in_string){
    let query_object_for_backend = {};
    for(let i = 0; i < query_data.length; i++) {
        query_object_for_backend[query_data[i].hintTextEn] = req_el_in_string[i]?.text || "Не знаю"
    }
    query_object_for_backend = converter_Query_Object_Into_Object_With_Code(query_object_for_backend, query_data);

    const searchParams = new URLSearchParams();
    for(const key in query_object_for_backend) {
        searchParams.append(key, query_object_for_backend[key]);
    }
    
    return searchParams.toString();
}

export function converter_Query_Object_Into_Object_With_Code(obj, query_data){
    let result = {...obj};
    for(let item of query_data){
        result[item.hintTextEn] = item.queryElements.indexOf(result[item.hintTextEn]);
        
    }
    return result;
  }