import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentIndex: 0,
    data: [],
}

const queryElementsInStrSlice = createSlice({
    name: "queryElementsInStr",
    initialState,
    reducers: {
        pushQueryElementInStr: {
            reducer (state, action) {
                let element = {
                    text: action.payload.text,
                    identify: action.payload.identify,
                    index: action.payload.index,
                }
                
                state.currentIndex++;
                state.data.push(element);
            },
            prepare (text, identify, index) {
                return {
                    payload: {
                        text,
                        identify,
                        index,
                    }
                }
            },
        },
        setByURLSearchParams: {
            reducer (state, action) {
                const params = new URLSearchParams(action.payload.params);
                const query_data = action.payload.query_data;
                let new_data = [];

                for(let i = 0; i < query_data.length; i++){
                    const URLParamsHasCurrentField = params.has(query_data[i].hintTextEn)

                    let text = URLParamsHasCurrentField ? params.get(query_data[i].hintTextEn) : "Не знаю";
                    text = text.replace('+', ' ');
                    const identify = text + i;
                    const index = i;
                    new_data.push({
                        text,
                        identify,
                        index,
                    });
                }

                state.currentIndex = new_data.length;
                state.data = new_data;
            },
            prepare(params, query_data) {
               
                return {
                    payload: {
                        params,
                        query_data,
                    }
                }
            },
        },
        deleteQueryElementsInStr(state, action){
            const index_element_deleted = action.payload;
            state.data = state.data.slice(0, index_element_deleted);
            state.currentIndex = index_element_deleted;
        },
    }
})

export const {pushQueryElementInStr, deleteQueryElementsInStr, setByURLSearchParams} = queryElementsInStrSlice.actions;

export default queryElementsInStrSlice.reducer;

export const selectAllQueryElements = (state) => state.queryElementsInStr.data;
export const selectCurrentIndex = (state) => state.queryElementsInStr.currentIndex;