import { useEffect, useState, useRef } from 'react';

import styles from './SearchTable.module.css';


export default function SearchTable({elements_for_sTable, hint_text}) {
    const [is_extended, setExtended] = useState(false);
    const main_block_ref = useRef();

    useEffect(() =>{
        setExtended(false);
    }, [elements_for_sTable]);

    useEffect(() => {
        if(main_block_ref){
            main_block_ref.current.classList.add(styles.main_block_appearance);
        }
        return () => main_block_ref && main_block_ref?.current?.classList?.remove(styles.main_block_appearance);
    }, [main_block_ref, hint_text])

    let processed_hint_text = hint_text && hint_text.replaceAll('_', ' ') + '?';


    let elements = elements_for_sTable;

    if(elements_for_sTable?.length > 20 && !is_extended){
        elements = elements_for_sTable.slice(0, 19);
        let extend_button = <div className={styles.extend_button} onClick={() => setExtended(true)}>
            Раскрыть
            <div className={styles.wrap_down_arrow}>
                <img className={styles.down_arrow} src={process.env.PUBLIC_URL + "/img/down-arrow.svg"} />
            </div>
        </div>;
        elements.push(extend_button);
    }
    

    return (
        <div className={`${styles.main_block}`} ref={main_block_ref}>
            <div className={styles.hint_text}>{processed_hint_text}</div>
            <div className={styles.block_with_req_el}>{elements}</div>
        </div>
    );
}