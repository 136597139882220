import { useSelector } from "react-redux";
import { getPreviousHref } from "../../store/previousHrefSlice";

import { useNavigate } from "react-router-dom";

import { IconButton } from "@mui/material";

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import styles from './BackButton.module.css';


export default function BackButton() {
    const previousHref = useSelector(getPreviousHref);
    const navigate = useNavigate();

    function handleClick(){
        previousHref ? navigate(previousHref) : navigate("/");
    }

    return (
        <IconButton className={styles.Button} onClick={handleClick}>
            <KeyboardBackspaceIcon fontSize="large"/>
        </IconButton>
    )
}