import {createSlice} from "@reduxjs/toolkit";

const isResponseModeSlice = createSlice({
    name: "isResponseMode",
    initialState: false,
    reducers: {
        setIsResponseMode (state, action) {
            return action.payload;
        }
    }
})

export const {setIsResponseMode} = isResponseModeSlice.actions;

export default isResponseModeSlice.reducer;