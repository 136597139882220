import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import {Provider} from 'react-redux';
import store from './store/store';

import App from './Components/App';
import ErrorPage from './Components/ErrorPage/ErrorPage';
import ResponseTable from './Components/ResponseTable/ResponseTable';
import ArticlePage from './Components/ArticlePage/ArticlePage';

import reportWebVitals from './reportWebVitals';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {index: true, element: <div></div>},
      {
        path: "search",
        element: <ResponseTable />
      },
    ],
  },
  {
    path: "article/:articleId",
    element: <ArticlePage />,
    errorElement: <ErrorPage />,
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
    
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
