import React, { useEffect } from 'react';

import SearchSpace from './SearchSpace/SearchSpace';
import Footer from './Footer/Footer';
import Loader from './Loader/Loader';
import ErrorPage from './ErrorPage/ErrorPage';

import {useGetQueryDataQuery} from '../store/apiSlice';

import styles from './App.module.css';
import { Outlet } from 'react-router-dom';

export default function App() {
    const {
        data: query_data,
        isLoading,
        isError,
        isSuccess,
    } = useGetQueryDataQuery();

    useEffect(() => {
        document.title = "Aptaran — Поисковая система";
    }, []);

    let content;

    if(isLoading) {
        content = <Loader />;
    }
    else if(isError) {
        content = <ErrorPage />;
    }
    else if(isSuccess){
        content = (
            <>
                <SearchSpace query_data={query_data} />
                <Outlet context={query_data} />
                <Footer />
            </>
        );
    }

    return (
        <div className={`${styles.App} ${isLoading && styles.App_loading}`}>
            {content}
        </div>
    ); 
} 