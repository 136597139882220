import styles from './ResponseTableItem.module.css';

import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useGetImageForArticleQuery } from '../../store/apiSlice';
import { setPreviousHref } from '../../store/previousHrefSlice';

export default function ResponseTableItem({id, title}){
    const {
        data: imageSrc,
        isSuccess,
    } = useGetImageForArticleQuery({id, name: "title"});
    const navigate = useNavigate();
    const location = useLocation();
    const [params] = useSearchParams();
    const dispatch = useDispatch();

    let src;

    if(isSuccess){
        src = imageSrc;
    }
    else{
        src = process.env.PUBLIC_URL + "/img/preloaded_main.svg";
    }

    function handleClick(){
        dispatch(setPreviousHref(location.pathname + '?' + params));
        navigate(`/article/${id}`);
    }

    return (
        <div className={styles.ResponseTableItem} onClick={handleClick}>
            <img className={styles.main_image} src={src}/>
            <div className={styles.item_title}>{title}</div>
        </div>
    );
}