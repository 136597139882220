import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';

import RequestElement from '../RequestElement/RequestElement';
import SearchBar from '../SearchBar/SearchBar';
import SearchTable from '../SearchTable/SearchTable';

import {selectAllQueryElements} from '../../store/queryElementsInStrSlice';
import {selectCurrentIndex} from '../../store/queryElementsInStrSlice';
import { setByURLSearchParams } from '../../store/queryElementsInStrSlice';

import styles from './SearchSpace.module.css';

export default function SearchSpace ({query_data})  {
    const dispatch = useDispatch();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const req_el_in_string = useSelector(selectAllQueryElements);
    const current_index = useSelector(selectCurrentIndex);

    const is_response_mode = location.pathname.includes("search");

    useEffect(() => {
        if(is_response_mode){
            const params = Array.from(searchParams.entries());
            dispatch(setByURLSearchParams(params, query_data));
        }
    }, [is_response_mode, dispatch, searchParams, query_data]);

    const is_query_string_complete = req_el_in_string.length === query_data.length;

    const current_elements = query_data[current_index]?.queryElements;
    
    const elements_for_sBar = req_el_in_string.map((el) => 
    <RequestElement inString={true} key={el.identify} identify={el.identify} index={el.index}>{el.text}</RequestElement>
    );

    const elements_for_sTable = current_elements?.map((el) => 
    <RequestElement inString={false} key={el} identify={el + current_index} index={current_index}>{el}</RequestElement>
    );

    return (
        <div className={styles.SearchSpace}>
            <SearchBar req_el_in_search_bar={elements_for_sBar} is_query_string_complete={is_query_string_complete} req_el_in_string={req_el_in_string} query_data={query_data}
            is_response_mode={is_response_mode}/>

            {!is_response_mode && <SearchTable hint_text={query_data[current_index]?.hintTextRu} elements_for_sTable={elements_for_sTable} /> }
        </div>
    );
    
}