import styles from "./ListOfContent.module.css";

export default function ListOfContent({list_of_content, setShowBackToTopButton}){
    const handleClick = () => setShowBackToTopButton(false);

    const content = list_of_content.map((node) =>{
        return (<li key={node.title} className={styles.H2}><a className={styles.Text} onClick={handleClick} href={`#${node.title.replace(/ /g, "-")}`}>{node.title}</a>
                    {node.subtitles &&
                        <ul>
                            {node.subtitles.map(h3 => <li key={h3} className={styles.H3}><a className={styles.Text} onClick={handleClick} href={`#${h3.replace(/ /g, "-")}`}>{h3}</a></li>)}
                        </ul>
                    }
                </li>
                );
    });

    return(
        <div className={styles.Wrapper}>
            <ul className={styles.List_of_h2}>
                {content}
            </ul>
        </div>
    );
}