import styles from './SearchBar.module.css';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useRef } from 'react';

export default function SearchBar({req_el_in_search_bar, is_query_string_complete, req_el_in_string, query_data, is_response_mode}) {
    const [, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const stringElRef = useRef();

    useEffect(() => {
        stringElRef.current.scrollTo({ left: 10000, behavior: 'instant' });
    }, [req_el_in_search_bar]);

    function handleClickOnSearchButton(e){
        e.preventDefault();
        const searchParams = new URLSearchParams();
        for(let i = 0; i < query_data.length; i++){
            searchParams.append(query_data[i].hintTextEn, req_el_in_string[i]?.text || "Не знаю")
        }
        
        navigate("/search");
        setSearchParams(searchParams);
    }

    const message = <div key="message" style={{margin:'4px 0px 0px 0px', flexShrink: 0,}}>из энергии</div>;
    if(req_el_in_search_bar.length > 1){
        req_el_in_search_bar.splice(1, 0, message) //Добавляем message после первого элемента в случае, если пользователь выбирает второй
    }
    
    
    return (
        <div className={`${styles.bar} ${is_response_mode && styles.bar_response_mode}`} >
            <div className={styles.box} >
                <div className={styles.string} ref={stringElRef}>
                    {req_el_in_search_bar.length === 0 ? <span style={{flexShrink: 0,}}>Сформулируйте свой запрос</span> : [ ...req_el_in_search_bar]} 
                </div>
            </div>

            <div className={`${styles.search_button} ${!is_query_string_complete && styles.search_button_incomplete}`} onClick={handleClickOnSearchButton}>
                <img className={styles.image_search_button} src={process.env.PUBLIC_URL + "/img/minimal-magnifying-glass_min.svg"} alt='Лупа для поиска'/>
            </div>
        </div>
    );
}