import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({baseUrl: 'https://api.aptaran.ru'}),
    endpoints: builder => ({
        getArticles: builder.query({
            query: ({parametersForArticlesQuery, page}) => `/getArticlesByTags/index.php?${parametersForArticlesQuery}&page=${page}`
        }),
        getArticleById: builder.query({
            query: (id) => `/getPageById/index.php?id=${id}`
        }),
        getQueryData: builder.query({
            query: () => '/queryData'
        }),
        getImageForArticle: builder.query({
            query: ({id, name}) => ({
                url: `getImage/index.php?id=${id}&name=${name}`,

                responseHandler: (response) => response.blob().then(blob => URL.createObjectURL(blob))
            }),
        }),
        getCountByTags: builder.query({
            query: (parametersForArticlesQuery) => `/getCountByTags/index.php?${parametersForArticlesQuery}`,
        }),
    })
})

export const {
    useGetArticlesQuery,
    useGetArticleByIdQuery,
    useGetQueryDataQuery,
    useGetImageForArticleQuery,
    useGetCountByTagsQuery,
} = apiSlice;