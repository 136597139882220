import {configureStore} from "@reduxjs/toolkit";

import isResponseModeReducer from './isResponseModeSlice';
import queryElementsInStrReducer from './queryElementsInStrSlice';
import previousHrefReducer from "./previousHrefSlice";
import { apiSlice } from "./apiSlice";

export default configureStore({
    reducer: {
        isResponseMode: isResponseModeReducer,
        queryElementsInStr: queryElementsInStrReducer,
        previousHref: previousHrefReducer,
        [apiSlice.reducerPath]: apiSlice.reducer, 
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
})