import { useState } from 'react';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import styles from './DropDownListOfContent.module.css';

export default function DropDownListOfContent({list_of_content, setShowBackToTopButton}) {
    const [open, setOpen] = useState(false);

    const handleClick = () => setShowBackToTopButton(false);

    const content = list_of_content.map((node) => {
        let result = [];
        result.push(
            <ListItemButton key={node.title} onClick={handleClick} component="a" href={`#${node.title.replace(/ /g, "-")}`} sx={{ pl: 2 }}>
                  <ListItemText primary={node.title} />
            </ListItemButton>
        );

        if(node.subtitles){
            
            const subtitles = node.subtitles.map((subtitle) => 
                <ListItemButton key={subtitle} onClick={handleClick} component="a" href={`#${subtitle.replace(/ /g, "-")}`} sx={{ pl: 4 }}>
                    <ListItemText primary={subtitle} />
                </ListItemButton>
            );
            
            result = result.concat(subtitles);
        }
        return result;
    }).flat();


    return (
        <List
            sx={{ width: '100%', bgcolor: '#F0F0F0', borderRadius: '10px' }}
            component="nav"
            disablePadding
            className={styles.Main}
        >   
     
            <ListItemButton onClick={() => setOpen((open) => !open)}>
              <ListItemText primary="Содержание" />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Divider component="li" />
                {content}
              </List>
            </Collapse>
        </List>
    )
}